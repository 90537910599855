import IntroScan from "../../../../assets/Partners/introscan.svg"
import AKS from "../../../../assets/Partners/aks.png"
import IRZ from "../../../../assets/Partners/ingt.png"
import Izhevsk from "../../../../assets/Partners/izh.png"

const styleTitle = {
    fontSize:18,
    color: "#183323",
    fontWeight:"bold",
    textAlign:"center"
}

export const ReviewsItem = [
    {
        img:IntroScan,
        alt:"Introscan",
        title: <h3 style={ styleTitle } className="InAk centreText">JSC "IntroScan Technology"</h3>,
        url:"https://www.introscan.ru/"
    },
    {
        img:AKS,
        alt:"AKS",
        title: <h3 style={ styleTitle } className="InAk centreText">LLC "Acoustic Control Systems"</h3>,
        url: "https://acsys.ru/"
    },
    {
        img:IRZ,
        alt:"IRZ",
        title: <h3 style={ styleTitle } className="RZD centreText">JSC "Izhevsk Radio Plant"</h3>,
        class:"IRZ",
        url: "https://www.irz.ru/"
    },
    {
        img:Izhevsk,
        alt:"Izhevsk",
        title: <h3 style={ styleTitle } className="centreText" >Izhevsk State Technical University named after M. T. Kalashnikov</h3>,
        url:"https://istu.ru/"
    }
]